import { cn } from '@hapstack/common'
import type { ComponentProps } from 'react'

export const PatternBackground = ({
  className,
  ...props
}: ComponentProps<'div'>) => {
  return (
    <div
      {...props}
      className={cn('h-full bg-repeat', className)}
      style={{
        backgroundImage: 'url(/images/bg-pattern-dark.png)',
        backgroundSize: '140px auto',
      }}
    />
  )
}
